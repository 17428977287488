<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { pagecontentMethods } from "../../../state/helpers/pagecontent";
import Toastify from 'toastify-js';
/**
 * Blank page component
 */
export default {
  page: {
    title: "Terms Of Service",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout,  ckeditor: CKEditor.component },
  data() {
    return {
      title: "Terms Of Service",
      editor: ClassicEditor,
      privacy: {content: '', name: 'terms-of-service'},
      editorConfig: {
        toolbarLocation: "bottom",
      },
    };
  },
  mounted() {
    this.getPageContent()
  },
  methods: {
    ...pagecontentMethods,
    runErrorToast(message) {
      Toastify({
        text: message,
        gravity: "top", // `top` or `bottom`
        position: "center", // `left`, `center` or `right`
        duration: 2000,
        style: {
          background: "red",
        }
      }).showToast();
    },
    getPageContent() {
      return this.showTermsOfServiceContent({ name: 'terms-of-service'}).then((response) => {
        // eslint-disable-next-line no-console
        if(response.data !== '') {
          this.privacy = response.data
        }
      })
    },
    handleSaveChanges() {
      
      if(this.privacy.content === '' || this.privacy === ''){
       return  this.runErrorToast("Sorry terms of service content field is required!")
      }
// eslint-disable-next-line no-console
      // console.log(this.privacy.id === undefined)
      if(this.privacy.id === undefined){
        this.createPageContent(this.privacy).then(() => {
          this.getPageContent()
        })
      } else {
        this.updatePageContent(this.privacy).then(() => {
          this.getPageContent()
        })
      }
      
  }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="mt-5">
          <b-card-body>
            <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
              <h1 class="card-title">Terms Of Service</h1>
              <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="handleSaveChanges">Save Changes</a>
              </b-card-title>

              <div class="col-md-12 mt-5 mb-5">
                  <ckeditor v-model="privacy.content" :config="editorConfig" :editor="editor"></ckeditor>
              </div>
            </b-card-body>
        </b-card>
      </div>
    </div>
  </Layout>
</template>